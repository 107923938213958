<template>
  <div>
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("facilitySwitchPage.pageTitle") }}</strong>
          </CCardHeader>
          <!-- start_userFacilitiesTable -->
          <CCardBody>
            <CDataTable
              class="mb-0 table-outline"
              hover
              size="sm"
              :items="userFacilities"
              :fields="userFacilitiesTableFields"
              head-color="light"
              pagination
            >
              <td
                slot="name"
                slot-scope="{ item }"
              >
                <strong>{{ item.name }}</strong>
              </td>
              <td
                slot="address1"
                slot-scope="{ item }"
              >
                <strong>{{ item.address1 }}</strong>
              </td>

              <td
                slot="telephone"
                slot-scope="{ item }"
              >
                <strong>{{ item.telephone }}</strong>
              </td>
              <td
                slot="email"
                slot-scope="{ item }"
              >
                <strong>{{ item.email }}</strong>
              </td>

              <template #facility_user_relation_delete="{item}">
                <td class="py-2 text-center">
                  <CButton
                    color="info"
                    square
                    block
                    size="sm"
                    @click="moveToDashboardPage(item.uniqueId)"
                  ><i class="cil-hand-point-right"></i>{{ $t("facilitySwitchPage.switch") }}</CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
          <!-- end_userFacilitiesTable -->
          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              v-if="uid"
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>

    <!-- TODO: -->
  </div>
</template>

<script>

import i18n from "@/i18n"
import axios from "axios"
import config from "@/config";

//会員システムのurl
const LIBERTY_MEMBER_SERVER_URL = config.LIBERTY_MEMBER_SERVER_URL;

export default {
  name: "FacilitySwitch",
  data() {
    return {
      //#region Flag
      loading: false,
      //#endregion Flag

      //#region Input
      currentUser:{},
      userFacilities:[],
      //#endregion Input

      //#region HTML table field
      userFacilitiesTableFields: [
        {
          key: "name",
          label: `${i18n.t(
            "facilitySwitchPage.name"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "address1",
          label: `${i18n.t(
            "facilitySwitchPage.address"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "telephone",
          label: `${i18n.t(
            "facilitySwitchPage.telephone"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "email",
          label: `${i18n.t(
            "facilitySwitchPage.email"
          )}`,
          _classes: "text-center bg-light",
        },
        {
          key: "facility_user_relation_delete",
          label: `${i18n.t(
            "facilitySwitchPage.switch"
          )}`,
          sorter: false,
          _classes: "text-center bg-light",
        },
      ],
      //#endregion HTML table field
    };
  },
  computed: {
    uid(){
      return this.$store.state.uniqueId;
    },
  },
  methods: {

    //# region event

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    //# endregion event


    //# region move page

    /**前のページに戻る */
    backPage() {
      this.$router.go(-1);
    },

    /**
     * 宿編集画面へ遷移
     */
    moveToDashboardPage(uniqueId) {
      this.$router.push({
        path: "/operator/dashboard"
      });
      this.$store.commit('setUid',uniqueId);
      console.log(this.$store.state.uniqueId)
    },

    //# endregion move page


    // # region request

        /**
     * 会員システムからログイン中のユーザー情報取得
     */
    getCurrentUserInfo() {
      //Login as registered user
      var urlRequest = `${LIBERTY_MEMBER_SERVER_URL}/rest/currentuser`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          this.currentUser = resp.data;
          this.currentUserId = this.currentUser.id;
          console.log("User Data:" + JSON.stringify(this.currentUser));
          this.saveUser(this.currentUser)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * 会員システムの情報を宿探しのt_userに上書き
     */
    // NOTE:宿探しに関連付けするユーザーを保存・すでに保存されてれば更新する
    saveUser(user){
      // 宿探しに保存するユーザー情報作成
      const requestUser = {
        id : user.id,
        userName : user.userName,
        firstName : user.firstName,
        lastName : user.lastName,
        familyName : user.familyName,
        givenName : user.givenName,
        email : user.email,
        telephone : user.mobile,
        description : "",
        status : user.status,
      }
      console.log(requestUser)
      
      var urlRequest = `/rest/user`;
      axios
        .post(
            urlRequest, 
            requestUser,
            {headers: { Authorization: `Bearer ${this.$keycloak.token}` },})
        .then((resp) => {
          console.log(resp.data);
          this.getUserFacilities();

        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 担当宿取得
    getUserFacilities() {
      var urlRequest = `/rest/user/${this.currentUserId}/hotel`;
      axios
        .get(urlRequest, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
         this.userFacilities = resp.data.hotels;

         if(!this.userFacilities.length >= 2){
          this.moveToDashboardPage(this.userFacilities[0].uniqueId);
         }

        })
        .catch((err) => {
          this.userFacilitiesNotFound = true;
          console.log(err);
          
        });
    },

    // # endregion request

  },
  beforeMount() {
    // # region method
    this.getCurrentUserInfo();
    // # endregion method
  },
};
</script>
