var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"12","lg":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("facilitySwitchPage.pageTitle")))])]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","size":"sm","items":_vm.userFacilities,"fields":_vm.userFacilitiesTableFields,"head-color":"light","pagination":""},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('strong',[_vm._v(_vm._s(item.name))])])}},{key:"address1",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('strong',[_vm._v(_vm._s(item.address1))])])}},{key:"telephone",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('strong',[_vm._v(_vm._s(item.telephone))])])}},{key:"email",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('strong',[_vm._v(_vm._s(item.email))])])}},{key:"facility_user_relation_delete",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('CButton',{attrs:{"color":"info","square":"","block":"","size":"sm"},on:{"click":function($event){return _vm.moveToDashboardPage(item.uniqueId)}}},[_c('i',{staticClass:"cil-hand-point-right"}),_vm._v(_vm._s(_vm.$t("facilitySwitchPage.switch")))])],1)]}}])})],1),_c('CCardFooter',[(_vm.uid)?_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.onBackClicked()}}},[_c('i',{staticClass:"icon cil-arrow-left mr-2"}),_vm._v(_vm._s(_vm.$t("common.back")))]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }